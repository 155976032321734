import React, {Component} from 'react';
import {IntlProvider, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {userPostFetch} from '../redux/actions';

class Signup extends Component {
  static defaultProps = {
    currentLocale: "[]"
 }
 constructor(props) {
     super(props);
     this.state = {
      name: "user",
      email: "",
      password: "",
      password_confirmation: ""
     }
 }


  state = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    submitted: false
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({submitted: true})
    this.props.userPostFetch(this.state)
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h1><i className="i_button" onClick={this.props.loginClick}><FormattedMessage id="app.login"/></i> / <strong><FormattedMessage id="app.sign_up"/></strong></h1>
         {/* <div className="input-wrap" style={{display: 'none'}}>
        <input
          id="signup-name"
          name='name'
          placeholder='name'
          value={this.state.name}
          onChange={this.handleChange.bind(this)}
          required
          />
          <label className="placeholder" htmlFor="signup-name">name</label>
        </div> */}
  
        <div className="input-wrap">
        <input
          id="signup-email"
          type='email'
          name='email'
          placeholder='Email'
          value={this.state.email}
          onChange={this.handleChange.bind(this)}
          required
          />
          <label className="placeholder" htmlFor="signup-email"><FormattedMessage id="app.email"/></label>
        </div>

        <div className="input-wrap">
        <input
          id="signup-password"
          type='password'
          name='password'
          placeholder='Password'
          value={this.state.password}
          onChange={this.handleChange.bind(this)}
          required
          />
          <label className="placeholder" htmlFor="signup-password"><FormattedMessage id="app.password"/></label>
        </div>

        <div className="input-wrap">
        <input
          id="signup-password_confirmation"
          type='password'
          name='password_confirmation'
          placeholder='Password again'
          value={this.state.password_confirmation}
          onChange={this.handleChange.bind(this)}
          required
          />
          <label className="placeholder" htmlFor="signup-password_confirmation"><FormattedMessage id="app.password_again"/></label>
        </div>

        <div className="btn-wrap">
          <button type='submit' className={`btn ${this.state.submitted ? "submitted" : ""}`}><FormattedMessage id="app.enter"/>
            <svg width="8.574358mm" height="9.8692408mm" viewBox="0 0 8.574358 9.8692408" version="1.1">
              <path  d="M 5.5116534,2.38803 2.2173964,0.38070997 c -0.514732,-0.22305 -1.85303797,0.0172 -1.90451297,1.37683003 l -0.01288,6.31798 c -0.0025,1.22139 1.39419097,1.80501 2.17474297,1.31254 l 5.404666,-3.40994 c 0.380397,-0.24 0.627785,-1.58927 0.05564,-2.02354 L 7.5835704,3.6878"/>
            </svg>
          </button>
        </div>
      </form>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  userPostFetch: userInfo => dispatch(userPostFetch(userInfo))
})

export default connect(null, mapDispatchToProps)(Signup);