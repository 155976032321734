const fetchURL = "https://open.cat.dp.ua";
export const getHomeFetch = e => {
  return dispatch => {
    return fetch(fetchURL + "/webApi/", {
      method: "GET",
      headers: {
        'x-client-lang': 'ru',
        'Accept': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (data) {
          dispatch(home(data))
        } else {
          console.log('error')
        }
      })
  }
}

const home = obj => ({
  type: 'HOME',
  payload: obj
})

export const getTranslationsLang = (e) => {
  console.log("getTranslationsLang = (e) = " + e);
  // return dispatch => {
  return fetch("https://dpua.github.io/awax/lang.json", {//https://awaxtech.com/api/getTranslations
    method: "POST",
    headers: {
      'x-client-lang': localStorage.locale,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ "lang": e })
  })
    .then(resp => resp.json())
    .then(data => {
      if (data.message) {
        //Тут прописываем логику
        console.log("getTranslationsLang=data.message")
        console.log(data.message)
      } else {
        //localStorage.setItem("lang", data.jwt)
        console.log("getTranslationsLang=data")
        console.log(data)
        // dispatch(getTranslations(data))
      }
    })
  // }
}

const getTranslations = userObj => ({
  type: 'Get_Translations',
  payload: userObj
})

export const userPostFetch = user => {
  return dispatch => {
    return fetch(fetchURL + "/webApi/register/", {
      method: "POST",
      headers: {
        'x-client-lang': localStorage.locale,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(user)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data) {
          localStorage.setItem("token", data?.access_token)
          dispatch(loginUser(data))
        } else {
          console.log('error')
        }
      })
  }
}

const loginUser = userObj => ({
  type: 'LOGIN_USER',
  payload: userObj
})

export const userPostForgot = user => {
  return dispatch => {
    return fetch("https://site.spilka.dp.ua/forgot.php", {
      method: "POST",
      headers: {
        'x-client-lang': localStorage.locale,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ user })
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.message) {
          //Тут прописываем логику
        } else {
          //localStorage.setItem("token", data.jwt)
          //dispatch(loginUser(data.user))
        }
      })
  }
}

export const userLoginFetch = user => {
  return dispatch => {
    return fetch(fetchURL + "/webApi/login/", {
      method: "POST",
      headers: {
        'x-client-lang': 'ru',
        'Accept': 'application/json',
      },
      body: JSON.stringify(user)
      // body: JSON.stringify({user})
    })
      .then(resp => resp.json())
      .then(data => {
        if (data) {
          localStorage.setItem("token", data?.access_token)
          dispatch(loginUser(data))
        } else {
          console.log('error')
        }
      })
  }
}

const serverError = userObj => ({
  type: 'ERROR',
  payload: userObj
})

const dashboard = userObj => ({
  type: 'DASHBOARD',
  payload: userObj
})
export const getDashboardFetch = () => {
  return dispatch => {
    const token = localStorage.token;
    if (token) {
      return fetch(fetchURL + "/webApi/dashboard/", {
        method: "GET",
        headers: {
          'x-client-lang': localStorage.locale,
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(resp => resp.json())
        .then(data => {
          if (data) {
            dispatch(dashboard(data));
          } else {
            console.log("error")
          }
        })
    }
  }
}

export const postDashboardKeyAddFetch = (key) => {
  return dispatch => {
    const token = localStorage.token;
    if (token) {
      return fetch(fetchURL + "/webApi/dashboard/key/add/", {
        method: "POST",
        headers: {
          'x-client-lang': localStorage.locale,
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({"key": key})
      })
        .then(resp => resp.json())
        .then(data => {
          if (data?.message == "Done") {
            // ok
            dispatch(getDashboardFetch())
          } else {
            // error
            dispatch(serverError((data?.message)?data.message:"Server error"))
          }
        })
    }
  }
}

export const getDashboardKeyRemoveDeviceFetch = (key) => {
  return dispatch => {
    const token = localStorage.token;
    if (token) {
      return fetch(fetchURL + "/webApi/dashboard/key/removeDevice/?key=" + key, {
        method: "GET",
        headers: {
          'x-client-lang': localStorage.locale,
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(resp => resp.json())
        .then(data => {
          if (data.message == "Done") {
            // ok
            dispatch(getDashboardFetch())
          } else {
            // error
            dispatch(serverError((data?.message)?data.message:"Server error"))
          }
        })
    }
  }
}

export const getLogoutUser = () => {
  return dispatch => {
    const token = localStorage.token;
    return fetch(fetchURL + "/webApi/logout/", {
      method: "GET",
      headers: {
        'x-client-lang': localStorage.locale,
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.message) {
          // Будет ошибка если token не дествительный
          localStorage.removeItem("token")
          dispatch(logoutUser(data))
        } else {
          // Удаление token из localStorage
          localStorage.removeItem("token")
          dispatch(logoutUser(data))
        }
      })
  }
}

export const logoutUser = () => ({
  type: 'LOGOUT_USER'
})



export const switchMenu = () => ({
  type: 'SWITCH_MENU'
})

