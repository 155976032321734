import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { BrowserRouter as Link } from 'react-router-dom';
import { getDashboardFetch, postDashboardKeyAddFetch, getDashboardKeyRemoveDeviceFetch } from '../redux/actions';

class Dashboard extends Component {

  state = {
    addKey: "",
    addKeyMask: "•••• •••• ••••",
    keyLength: 14,
    addKeyError: false,
    serverError: false,
    submitted: false,
    show_license: false,
    tab: 0
  }

  componentDidMount = () => {
    this.props.getDashboardFetch()
  }
  tab = i => {
    this.setState({ tab: i })
  }

  handleChange = event => {
    let key = event.target.value.replace(/[^\d.]/ig, "").replace(/(.{4})/g, '$1 ').trim();
    let keyMask = '•••• •••• ••••';
    let Mask = '';
    for (let i = 0; i < 14; i++) {
      Mask += (key?.[i]) ? key?.[i] : keyMask?.[i];

    }
    this.setState({
      addKeyError: false,
      serverError: false,
      [event.target.name]: key,
      addKeyMask: Mask
    });
  }

  postDashboardKeyAddFetch = (key) => {
    const token = localStorage.token;
    if (token) {
      const fetchURL = "https://open.cat.dp.ua";
      return fetch(fetchURL + "/webApi/dashboard/key/add/", {
        method: "POST",
        headers: {
          'x-client-lang': localStorage.locale,
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ "key": key })
      })
        .then(resp => resp.json())
        .then(data => {
          if (data?.message == "Done") {
            // ok
            this.props.getDashboardFetch()
            this.setState({ submitted: false, addKey: '', addKeyMask: "•••• •••• ••••" })
          } else {
            // error
            this.setState({ submitted: false, serverError: true })
          }
        })
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    console.log(this.state.addKey)
    if (this.state.addKey.length !== this.state.keyLength) {
      this.setState({ addKeyError: true })
      return false
    }
    this.setState({ submitted: true })
    this.postDashboardKeyAddFetch(this.state.addKey.replace(/[^\d.]/ig, ""))
  }

  handleRemove = (key, e) => {
    e.preventDefault()
    console.log(key)
    this.props.getDashboardKeyRemoveDeviceFetch(key)
  }

  showLicense = e => {
    this.setState({ show_license: !this.state.show_license })
  }

  render() {
    return (
      <section className="works-section" id="dashboard" >

        <Link className="menu" to="/"  ><svg id="menu_icon" width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" className="menu_active">

          <path className="path_d" d="m 24.28125,20.720355 c 0.535204,0.27518 0.535204,0.969539 0,1.244719 l -3.572507,1.836881 c -0.531087,0.273081 -1.191893,-0.072 -1.191893,-0.622359 v -3.673763 c 0,-0.550392 0.660806,-0.895441 1.191893,-0.622359 z" fill="#DA1C5C"></path>

          <path className="path_a" d="M 7,5 C 5.7894785,5 4.7862649,6.0119972 4.7862649,7.2687576 v 2.8351874 c 0,1.26423 26.2182871,27.404787 27.4288091,27.404787 h 2.766427 c 1.226282,0 2.213735,-1.004528 2.213735,-2.268758 V 32.404787 C 37.195236,31.148027 10.992709,5 9.7664274,5 Z" fill="#DA1C5C"></path>

          <path className="path_b" d="m 33.027574,5.5018382 c -1.186123,0 -27.7552531,26.7810768 -27.7552531,27.8377258 v 2.388428 c 0,1.058725 0.958836,1.911255 2.144959,1.911255 h 2.6826351 c 1.188714,0 27.756211,-26.781077 27.756211,-27.8377258 V 7.413093 c 0,-1.0587245 -0.957203,-1.9112548 -2.145917,-1.9112548 Z" fill="#DA1C5C"></path>

          <path className="path_c" d="m 33.027574,5.5018382 c -1.186123,0 -27.7552531,26.7810768 -27.7552531,27.8377258 v 2.388428 c 0,1.058725 0.958836,1.911255 2.144959,1.911255 h 2.6826351 c 1.188714,0 27.756211,-26.781077 27.756211,-27.8377258 V 7.413093 c 0,-1.0587245 -0.957203,-1.9112548 -2.145917,-1.9112548 Z" fill="#DA1C5C"></path></svg></Link>

        <div className="container">
          <div className="works-tabs">
            <div className="works-tabs-header">
              <div className="works-tabs-title row">
                <div className={"works-tabs-title__item" + ((this.state.tab === 0) ? " active" : "")} data-page="0" onClick={this.tab.bind(this, 0)}>
                  <img src="./images/Licenses.svg" width="34" height="28" alt="licenses" />
                  <FormattedMessage id="licenses" />
                </div>
                <div className={"works-tabs-title__item" + ((this.state.tab === 1) ? " active" : "")} data-page="1" onClick={this.tab.bind(this, 1)}>
                  <img src="./images/purchase.svg" width="30" alt="purchase" />
                  <FormattedMessage id="purchases" />
                </div>

                <span className="link" id="add-btn-license" onClick={this.showLicense.bind(this)}><FormattedMessage id="add_licenses" /></span>
              </div>
            </div>
            <div className="works-tabs-box">
              <div className="works-tabs-box__item" >

                <form onSubmit={this.handleSubmit.bind(this)} className={'license_form ' + ((this.state.show_license) ? 'open' : 'close')}>
                  <div className="input-wrap">
                    <input
                      style={{ position: "absolute", zIndex: "1" }}
                      id="add_key"
                      type='text'
                      name='addKey'
                      placeholder='add key'
                      value={this.state.addKey}
                      onChange={this.handleChange.bind(this)}
                      onBlur={this.handleChange.bind(this)}
                      // minLength={this.state.keyLength}
                      // maxLength={this.state.keyLength}
                      className={((this.state.addKeyError) ? "input_error" : "")}
                      autocomplete="off"
                    // required
                    />
                    {/* <input
                      value={this.state.addKeyMask}
                      autocomplete="off"
                    /> */}
                    {/* <label className="placeholder" htmlFor="add_key"><FormattedMessage id="app.add_key" /></label> */}
                    <p className="input_mask">{this.state.addKeyMask.split('').map((e) => <i className={(e == "•") ? "spot" : ""}>{(e == "•") ? "8" : e}</i>)}</p>
                    {(this.state.addKeyError) ? <i><FormattedMessage id="enter_key" /></i> : ""}
                    {(this.state.serverError) ? <i><FormattedMessage id="server_error" /></i> : ""}
                  </div>
                  <div className="btn-wrap">
                    <button type='submit' className={`btn ${this.state.submitted ? "submitted" : ""}`}><FormattedMessage id="app.enter" />
                      <svg width="8.574358mm" height="9.8692408mm" viewBox="0 0 8.574358 9.8692408" version="1.1">
                        <path d="M 5.5116534,2.38803 2.2173964,0.38070997 c -0.514732,-0.22305 -1.85303797,0.0172 -1.90451297,1.37683003 l -0.01288,6.31798 c -0.0025,1.22139 1.39419097,1.80501 2.17474297,1.31254 l 5.404666,-3.40994 c 0.380397,-0.24 0.627785,-1.58927 0.05564,-2.02354 L 7.5835704,3.6878" />
                      </svg>
                    </button>
                  </div>
                </form>

                {/* {
  "devices": [],
  "keys": [
    {
      "id": 11,
      "key": "669405690766",
      "active": 0,
      "duration": 100,
      "activate_time": null,
      "end_time": null,
      "user_id": 3,
      "created_at": null,
      "updated_at": "2021-09-25 14:43:07",
      "type": "month",
      "comment": null,
      "retailer_id": null,
      "generation_id": 3,
      "deviceSystem": null,
      "deleted_at": null,
      "ip_id": null,
      "device": null
    }
  ],
  "purchases": []
} */}

                {(this.props.dashboard?.message) ?
                  <div className="table">
                    <div className="table-row">
                      <div className="col">{this.props.dashboard?.message}</div>
                    </div>
                  </div>
                  :
                  ((this.state.tab === 0) ? this.props.dashboard?.keys?.map((item) =>
                    <div key={item?.key} className="table">
                      <div className="table-row">
                        <div className="col">
                          <b><FormattedMessage id="device" /></b>
                          <p>{(item?.device) ? item?.device : <FormattedMessage id="no_device" />}</p>
                        </div>
                        <div className="col">
                          <b><FormattedMessage id="licenses" /></b>
                          <p>{item?.key}</p>
                        </div>
                        <div className="col">
                          <b><FormattedMessage id="activation_date" /></b>
                          <p>{item?.activate_time}</p>
                        </div>
                        <div className="col">
                          <b><FormattedMessage id="expiry_date" /></b>
                          <p>{item?.end_time}</p>
                        </div>
                      </div>
                      {(!item?.active) ?
                        <div className="col-btn">
                          <a href={"#" + item?.key} data-key={item?.key} className="btn" onClick={this.handleRemove.bind(this, item?.key)}><FormattedMessage id="remove_device" /></a>
                        </div>
                        : ''}
                    </div>
                  ) : this.props.dashboard?.purchases?.map((item) =>
                    <div key={item?.key} className="table"></div>))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  serverError: state.serverError
})

const mapDispatchToProps = dispatch => ({
  getDashboardFetch: () => dispatch(getDashboardFetch()),
  postDashboardKeyAddFetch: (key) => dispatch(postDashboardKeyAddFetch(key)),
  getDashboardKeyRemoveDeviceFetch: (key) => dispatch(getDashboardKeyRemoveDeviceFetch(key))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);