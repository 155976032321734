import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Shop from './Shop';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';
import reducer from './reducer';
import { BrowserRouter} from 'react-router-dom';
import "./page.js";
import "./index.css";

import {IntlProvider, FormattedMessage} from 'react-intl';
import ru from './lang/ru.json';
import en from './lang/en.json';
import Dashboard from './components/Dashboard';
import {BrowserRouter as Router, Switch, NavLink, Link, Route, useRouteMatch } from 'react-router-dom';


const store = createStore(reducer, applyMiddleware(thunk));  

const defaultLocale = "en";//localStorage['locale'] ? localStorage['locale'] : 'en'; 
const localeList = [
    { name: 'English', code: 'en', lang: 'English' },
    { name: 'Русский', code: 'ru', lang: 'Russian' }
];
const messages={
    en: en,
    ru: ru
}

class Download extends Component {
    render() {
        return (
          <div className="download_popap popap">
            <h2><strong><FormattedMessage id="app.14_days"/> </strong> <FormattedMessage id="app.free"/></h2>
            <button><FormattedMessage id="app.download_app_now"/></button>
          </div>
        )
    }
}
class Possibility extends Component {
    render() {
        return (
          <div className="possibility_popap resizable ">
            {/* <div className="resizer top" id="swipe-wrap"></div> */}
            <div className="possibility_popap_content">
                <div className="resizer top" id="swipe-wrap"></div>
                <section className="scroll_resizable">
                    <h1><strong><FormattedMessage id="app.possibility"/> </strong> <FormattedMessage id="app.app"/></h1>
                    <p><FormattedMessage id="app.about_this"/> <FormattedMessage id="app.apps"/> </p>
                        <figure className="possibility_popap_content_block_figure left">
                            <FormattedMessage id="app.content_without_ads">
                                {msg => (<img src="./images/possibility_1.png" alt={msg} />)}
                            </FormattedMessage>
                            <figcaption>
                                <div>
                                    <h2><FormattedMessage id="app.content_without_ads"/></h2>
                                    <p><FormattedMessage id="app.content_without_ads__description"/></p>
                                </div>
                            </figcaption>
                        </figure>
                        <figure className="possibility_popap_content_block_figure right">
                            <FormattedMessage id="app.content_without_ads">
                                {msg => (<img src="./images/possibility_2.png" alt={msg} />)}
                            </FormattedMessage>
                            <figcaption>
                                <div>
                                    <h2><FormattedMessage id="app.offline_mode"/></h2>
                                    <p><FormattedMessage id="app.offline_mode__description"/></p>
                                </div>
                            </figcaption>
                        </figure>
                        <figure className="possibility_popap_content_block_figure left">
                            <FormattedMessage id="app.content_without_ads">
                                {msg => (<img src="./images/possibility_3.png" alt={msg} />)}
                            </FormattedMessage>
                            <figcaption>
                                <div>
                                    <h2><FormattedMessage id="app.background_mode"/></h2>
                                    <p><FormattedMessage id="app.background_mode__description"/></p>
                                </div>
                            </figcaption>
                        </figure>



                    <div className="possibility_popap_content_block">
                        <figure className="phone_figure left">
                            <img src="./images/possibility/3_Home.png" alt="Ad.png" />
                            <figcaption>Ad</figcaption>
                        </figure>

                        <article>
                            <h2>Ad.png</h2>
                            <p>
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                            </p>
                        </article>

                        </div><div className="possibility_popap_content_block">

                        <figure className="phone_figure right">
                            <img src="./images/possibility/3_Home.png" alt="3_Home.png" />
                            <figcaption>3_Home</figcaption>
                        </figure>

                        <article>
                            <h2>Batch Downloading.</h2>
                            <p>
                                Make a collection of songs and videos while exploring and set them to download in just one go. This is the power of multiple downloading feature. Download hundreds of songs with just a click.
                            </p>
                        </article>

                        </div><div className="possibility_popap_content_block">

                        <figure className="phone_figure left">
                            <img src="./images/possibility/5_Video_QualityList.png" alt="5_Video_QualityList.png" />
                            <figcaption>5_Video_QualityList.png</figcaption>
                        </figure>

                        <article>
                            <h2>Upto 10X Faster Downloading.</h2>
                            <p>
                                Videoder uses multiple network connections to accelerate the downloading speed. You can set the number of connections according to your network quality to gain maximum speed.
                            </p>
                        </article>

                        </div><div className="possibility_popap_content_block">

                        <figure className="phone_figure right">
                            <img src="./images/possibility/8_Settings.png" alt="8_Settings.png" />
                            <figcaption>8_Settings.png</figcaption>
                        </figure>

                        <article>
                            <h2>Upto 10X Faster Downloading.</h2>
                            <p>
                                Videoder uses multiple network connections to accelerate the downloading speed. You can set the number of connections according to your network quality to gain maximum speed.
                            </p>
                        </article>
                    </div>


                </section>

            </div>
          </div>
        )
    }
  }
class Demo extends Component { 
    render() {
        return (
        <div className="demo_popap popap">
            <button className="btn left_arrow">
                <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.70592 15.0069L16.7426 1.97027C17.1933 1.51951 17.1933 0.788724 16.7426 0.337967C16.2918 -0.112656 15.561 -0.112656 15.1103 0.337967L1.25746 14.1908C0.806839 14.6415 0.806839 15.3723 1.25746 15.8231L15.1103 29.6759C15.5689 30.1188 16.2997 30.1061 16.7426 29.6475C17.1746 29.2001 17.1746 28.4909 16.7426 28.0436L3.70592 15.0069Z" fill="white"/>
                </svg>
            </button>
            <button className="btn right_arrow active">
                <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.2941 15.0069L1.25742 1.97027C0.806728 1.51951 0.806728 0.788724 1.25742 0.337967C1.70824 -0.112656 2.43897 -0.112656 2.88973 0.337967L16.7425 14.1908C17.1932 14.6415 17.1932 15.3723 16.7425 15.8231L2.88973 29.6759C2.43112 30.1188 1.70033 30.1061 1.25742 29.6475C0.825397 29.2001 0.825397 28.4909 1.25742 28.0436L14.2941 15.0069Z" fill="white"/>
                </svg>
                <FormattedMessage id="app.next"/>
            </button>
        </div>
        )
    }
}
class PhoneVideo extends Component {
    render() {
        return ( 
            <div className="phone_block video_content">
            <video className="video_block" src="images/video.mp4" poster="" type="video/mp4" playsInline loop autoPlay muted>
            Sorry, your browser doesn't support embedded videos,
            and watch it with your favorite video player!
            </video>
                <div>
                    <h1><FormattedMessage id="app.watch_youtube"/></h1>
                    <FormattedMessage id="app.videos_without_ads"/>
                    <br/>
                    </div>
                    <div>
                    <h2>
                    <FormattedMessage id="app.download_your_favorite"/>
                    </h2>
                    <FormattedMessage id="app.video_to_your_devices_memory"/>
                    </div>
                    <div>
                    <br/>
                </div>
            </div>
        )
    } 
}
class HowItWork extends Component {
    render() {
        return ( 
        <div className="phone_block how_it_work_content">
            <figure className="how_it_work_content_phone_figure how_01 active">
                <img className="how_it_work_content_phone_figure_img" src="./images/demo/how_bg_01.jpg" alt="Ad.png" />
                <img className="how_it_work_content_phone_figure_caption" src="./images/demo/how_01.png" />
                <figcaption><FormattedMessage id="get_access_to_the_app"/> <strong><FormattedMessage id="14_days_free"/></strong></figcaption>
            </figure>
            <figure className="how_it_work_content_phone_figure how_02">
                <img className="how_it_work_content_phone_figure_img" src="./images/demo/how_bg_02.jpg" alt="Ad.png" />
                <img className="how_it_work_content_phone_figure_caption" src="./images/demo/how_02.png" />
                <figcaption><div><strong><FormattedMessage id="how.1"/></strong><br/><FormattedMessage id="how.2"/></div>
                    <ul>
                        <li><FormattedMessage id="how.3"/></li>
                        <li><FormattedMessage id="how.4"/></li>
                        <li><FormattedMessage id="how.5"/></li>
                        <li><FormattedMessage id="how.6"/></li>
                        <li><FormattedMessage id="how.7"/></li>
                    </ul>
                </figcaption>
            </figure>
            <figure className="how_it_work_content_phone_figure how_04">
                <img className="how_it_work_content_phone_figure_img" src="./images/demo/how_bg_03.jpg" alt="Ad.png" />
                <img className="how_it_work_content_phone_figure_caption" src="./images/demo/how_03.png" />
                <figcaption><FormattedMessage id="how.8"/></figcaption>
            </figure>
            <figure className="how_it_work_content_phone_figure how_05">
                <img className="how_it_work_content_phone_figure_img" src="./images/demo/how_bg_04.jpg" alt="Ad.png" />
                <img className="how_it_work_content_phone_figure_caption" src="./images/demo/how_04.png" />
                <figcaption><FormattedMessage id="how.9"/></figcaption>
            </figure>
            <figure className="how_it_work_content_phone_figure how_06">
                <img className="how_it_work_content_phone_figure_img" src="./images/demo/how_bg_05.jpg" alt="Ad.png" />
                <img className="how_it_work_content_phone_figure_caption" src="./images/demo/how_05.png" />
                <figcaption><FormattedMessage id="how.10"/></figcaption>
            </figure>
            <figure className="how_it_work_content_phone_figure how_07">
                <img className="how_it_work_content_phone_figure_img" src="./images/demo/how_bg_05.jpg" alt="Ad.png" />
                <img className="how_it_work_content_phone_figure_caption" src="./images/demo/how_06.png" />
                <figcaption><FormattedMessage id="how.11"/></figcaption>
            </figure>
            <figure className="how_it_work_content_phone_figure how_08">
                <img className="how_it_work_content_phone_figure_img" src="./images/demo/how_bg_01.jpg" alt="Ad.png" />
                <img className="how_it_work_content_phone_figure_caption" src="./images/demo/how_01.png" />
                <figcaption><FormattedMessage id="how.12"/> <strong><FormattedMessage id="14_days_free"/></strong>
                <button><FormattedMessage id="how.13"/></button>
                </figcaption>
            </figure>
        </div>
        )
    } 
}

class News extends Component {
    render() {
        return (
          <div className="news_popap">
              <div className="resizer top" id="swipe-wrap2"></div>
                <h1><strong><FormattedMessage id="news"/> </strong> <FormattedMessage id="app"/></h1>
                <p><FormattedMessage id="about this Possibility"/></p>

                <div className="news_block">
                        <div className="close"></div>
                        <figure className="news_block_figure">
                            <img src="./images/news/img_1.png" alt="Ad.png" />
                            <figcaption>Ad</figcaption>
                        </figure>
                        <article className="news_block_article">
                            <h2 className="news_block_open">Ad.png</h2>
                            <p>
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                                1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8
                            </p>
                        </article>
                    </div>

                    <div className="news_block">
                        <div className="close"></div>
                        <figure className="news_block_figure">
                            <img src="./images/news/img_3.png" alt="3_Home.png" />
                            <figcaption>3_Home</figcaption>
                        </figure>
                        <article className="news_block_article">
                            <h2 className="open">Batch Downloading.</h2>
                            <p>
                                Make a collection of songs and videos while exploring and set them to download in just one go. This is the power of multiple downloading feature. Download hundreds of songs with just a click.
                        
                                Make a collection of songs and videos while exploring and set them to download in just one go. This is the power of multiple downloading feature. Download hundreds of songs with just a click.
                           
                                Make a collection of songs and videos while exploring and set them to download in just one go. This is the power of multiple downloading feature. Download hundreds of songs with just a click.
                            </p>
                        </article>
                    </div>
                    <div className="news_block">
                        <div className="close"></div>
                        <figure className="news_block_figure">
                            <img src="./images/news/img_1.png" alt="Ad.png" />
                            <figcaption>Ad</figcaption>
                        </figure>
                        <article className="news_block_article">
                            <h2 className="news_block_open">Ad.png</h2>
                            <p>
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                            </p>
                        </article>
                    </div>

                    <div className="news_block">
                        <div className="close"></div>
                        <figure className="news_block_figure">
                            <img src="./images/news/img_3.png" alt="3_Home.png" />
                            <figcaption>3_Home</figcaption>
                        </figure>
                        <article className="news_block_article">
                            <h2 className="open">Batch Downloading.</h2>
                            <p>
                                Make a collection of songs and videos while exploring and set them to download in just one go. This is the power of multiple downloading feature. Download hundreds of songs with just a click.
                            </p>
                        </article>
                    </div>
                    <div className="news_block">
                        <div className="close"></div>
                        <figure className="news_block_figure">
                            <img src="./images/news/img_1.png" alt="Ad.png" />
                            <figcaption>Ad</figcaption>
                        </figure>
                        <article className="news_block_article">
                            <h2 className="news_block_open">Ad.png</h2>
                            <p>
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                            </p>
                        </article>
                    </div>

                    <div className="news_block">
                        <div className="close"></div>
                        <figure className="news_block_figure">
                            <img src="./images/news/img_3.png" alt="3_Home.png" />
                            <figcaption>3_Home</figcaption>
                        </figure>
                        <article className="news_block_article">
                            <h2 className="open">Batch Downloading.</h2>
                            <p>
                                Make a collection of songs and videos while exploring and set them to download in just one go. This is the power of multiple downloading feature. Download hundreds of songs with just a click.
                            </p>
                        </article>
                    </div>
                    <div className="news_block">
                        <div className="close"></div>
                        <figure className="news_block_figure">
                            <img src="./images/news/img_1.png" alt="Ad.png" />
                            <figcaption>Ad</figcaption>
                        </figure>
                        <article className="news_block_article">
                            <h2 className="news_block_open">Ad.png</h2>
                            <p>
                                Videoder currently lets you download videos from sites like youtube, instagram, facebook, hotstar, voot, vk and new ones are being added every week as per our users request.
                            </p>
                        </article>
                    </div>

                    <div className="news_block">
                        <div className="close"></div>
                        <figure className="news_block_figure">
                            <img src="./images/news/img_3.png" alt="3_Home.png" />
                            <figcaption>3_Home</figcaption>
                        </figure>
                        <article className="news_block_article">
                            <h2 className="open">Batch Downloading.</h2>
                            <p>
                                Make a collection of songs and videos while exploring and set them to download in just one go. This is the power of multiple downloading feature. Download hundreds of songs with just a click.
                            </p>
                        </article>
                    </div>
          </div>
        )
    }
  }
class MenuButton extends Component {
    static defaultProps = {
        currentLocale: "[]"
     }
     constructor(props) {
         super(props);
         this.state = {
 
         }
     }
    render(){
      return (
        <div className="main_header">
            <div className="language">
                <div className="language-current">
                    {this.props.currentLocale}
                    <span className="arrow">
                        <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 477.2 252.2">
                            <g>
                                <path className="st0" d="M238.6,219.5L23.1,4C17.8-1.3,9.3-1.3,4,4s-5.3,13.8,0,19.1l225.1,225.1c5.3,5.3,13.8,5.3,19.1,0l225-225.1c2.6-2.6,4-6.1,4-9.5s-1.3-6.9-4-9.5c-5.3-5.3-13.8-5.3-19.1,0L238.6,219.5z"></path>
                            </g>
                        </svg>
                    </span>
                </div>
                <ul className="language-dropdown">
                    {
                        localeList.map((locale,index)=>(
                        <li key={index} >
                            <button lang={locale.code}  onClick={this.props.onChangeLanguage} >{locale.name}</button></li>
                        ))
                    }
				</ul>
			</div>

            <div className="menu" id="menu_icon" >
                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">

                    <path className="path_d" d="m 39.3109,31.5209 c 1.4688,0.8393 1.4688,2.9571 0,3.7964 l -9.8043,5.6025 c -1.4575,0.8329 -3.271,-0.2196 -3.271,-1.8982 v -11.205 c 0,-1.6787 1.8135,-2.7311 3.271,-1.8982 z" fill="#DA1C5C">
                        <animate className="button-pause" attributeName="d" attributeType="XML" dur="0.15s" begin="none" fill="freeze" 
                        from="m 39.3109,31.5209 c 1.4688,0.8393 1.4688,2.9571 0,3.7964 l -9.8043,5.6025 c -1.4575,0.8329 -3.271,-0.2196 -3.271,-1.8982 v -11.205 c 0,-1.6787 1.8135,-2.7311 3.271,-1.8982 z" 
                        to="m 24.28125,20.720355 c 0.535204,0.27518 0.535204,0.969539 0,1.244719 l -3.572507,1.836881 c -0.531087,0.273081 -1.191893,-0.072 -1.191893,-0.622359 v -3.673763 c 0,-0.550392 0.660806,-0.895441 1.191893,-0.622359 z" />
                        <animate className="button-play" attributeName="d" attributeType="XML" dur="0.15s" begin="none" fill="freeze" 
                        from="m 24.28125,20.720355 c 0.535204,0.27518 0.535204,0.969539 0,1.244719 l -3.572507,1.836881 c -0.531087,0.273081 -1.191893,-0.072 -1.191893,-0.622359 v -3.673763 c 0,-0.550392 0.660806,-0.895441 1.191893,-0.622359 z" 
                        to="m 39.3109,31.5209 c 1.4688,0.8393 1.4688,2.9571 0,3.7964 l -9.8043,5.6025 c -1.4575,0.8329 -3.271,-0.2196 -3.271,-1.8982 v -11.205 c 0,-1.6787 1.8135,-2.7311 3.271,-1.8982 z" />
                    </path> 

                    <path className="path_a" d="M 4.3730469,0 C 1.950632,0 0,1.950632 0,4.3730469 v 5.4648437 c 0,2.4224154 1.950632,4.3730474 4.3730469,4.3730474 h 5.4648437 c 2.4224154,0 4.3730474,-1.950632 4.3730474,-4.3730474 V 4.3730469 C 14.210938,1.950632 12.260306,0 9.8378906,0 Z" fill="#DA1C5C">
                        <animate className="button-pause" attributeName="d" attributeType="XML" dur="0.15s" begin="none" fill="freeze" 
                        from="M 4.3730469,0 C 1.950632,0 0,1.950632 0,4.3730469 v 5.4648437 c 0,2.4224154 1.950632,4.3730474 4.3730469,4.3730474 h 5.4648437 c 2.4224154,0 4.3730474,-1.950632 4.3730474,-4.3730474 V 4.3730469 C 14.210938,1.950632 12.260306,0 9.8378906,0 Z" 
                        to="M 7,5 C 5.7894785,5 4.7862649,6.0119972 4.7862649,7.2687576 v 2.8351874 c 0,1.26423 26.2182871,27.404787 27.4288091,27.404787 h 2.766427 c 1.226282,0 2.213735,-1.004528 2.213735,-2.268758 V 32.404787 C 37.195236,31.148027 10.992709,5 9.7664274,5 Z" />
                        <animate className="button-play" attributeName="d" attributeType="XML" dur="0.15s" begin="none" fill="freeze" 
                        from="M 7,5 C 5.7894785,5 4.7862649,6.0119972 4.7862649,7.2687576 v 2.8351874 c 0,1.26423 26.2182871,27.404787 27.4288091,27.404787 h 2.766427 c 1.226282,0 2.213735,-1.004528 2.213735,-2.268758 V 32.404787 C 37.195236,31.148027 10.992709,5 9.7664274,5 Z" 
                        to="M 4.3730469,0 C 1.950632,0 0,1.950632 0,4.3730469 v 5.4648437 c 0,2.4224154 1.950632,4.3730474 4.3730469,4.3730474 h 5.4648437 c 2.4224154,0 4.3730474,-1.950632 4.3730474,-4.3730474 V 4.3730469 C 14.210938,1.950632 12.260306,0 9.8378906,0 Z" />
                    </path> 

                    <path className="path_b" d="m 30.607422,0 c -2.422415,0 -4.371094,1.950632 -4.371094,4.3730469 v 5.4648437 c 0,2.4224154 1.948679,4.3730474 4.371094,4.3730474 h 5.466797 c 2.422415,0 4.373047,-1.950632 4.373047,-4.3730474 V 4.3730469 C 40.447266,1.950632 38.496634,0 36.074219,0 Z" fill="#DA1C5C">
                        <animate className="button-pause" attributeName="d" attributeType="XML" dur="0.15s" begin="none" fill="freeze" 
                        from="m 30.607422,0 c -2.422415,0 -4.371094,1.950632 -4.371094,4.3730469 v 5.4648437 c 0,2.4224154 1.948679,4.3730474 4.371094,4.3730474 h 5.466797 c 2.422415,0 4.373047,-1.950632 4.373047,-4.3730474 V 4.3730469 C 40.447266,1.950632 38.496634,0 36.074219,0 Z" 
                        to="m 33.027574,5.5018382 c -1.186123,0 -27.7552531,26.7810768 -27.7552531,27.8377258 v 2.388428 c 0,1.058725 0.958836,1.911255 2.144959,1.911255 h 2.6826351 c 1.188714,0 27.756211,-26.781077 27.756211,-27.8377258 V 7.413093 c 0,-1.0587245 -0.957203,-1.9112548 -2.145917,-1.9112548 Z" />
                        <animate className="button-play" attributeName="d" attributeType="XML" dur="0.15s" begin="none" fill="freeze" 
                        from="m 33.027574,5.5018382 c -1.186123,0 -27.7552531,26.7810768 -27.7552531,27.8377258 v 2.388428 c 0,1.058725 0.958836,1.911255 2.144959,1.911255 h 2.6826351 c 1.188714,0 27.756211,-26.781077 27.756211,-27.8377258 V 7.413093 c 0,-1.0587245 -0.957203,-1.9112548 -2.145917,-1.9112548 Z" 
                        to="m 30.607422,0 c -2.422415,0 -4.371094,1.950632 -4.371094,4.3730469 v 5.4648437 c 0,2.4224154 1.948679,4.3730474 4.371094,4.3730474 h 5.466797 c 2.422415,0 4.373047,-1.950632 4.373047,-4.3730474 V 4.3730469 C 40.447266,1.950632 38.496634,0 36.074219,0 Z" />
                    </path> 

                    <path className="path_c" d="M 4.3730469,26.271484 C 1.950632,26.271484 0,28.222116 0,30.644531 v 5.464844 c 0,2.422415 1.950632,4.373047 4.3730469,4.373047 h 5.4648437 c 2.4224154,0 4.3730474,-1.950632 4.3730474,-4.373047 v -5.464844 c 0,-2.422415 -1.950632,-4.373047 -4.3730474,-4.373047 Z"  fill="#DA1C5C">
                        <animate className="button-pause" attributeName="d" attributeType="XML" dur="0.15s" begin="none" fill="freeze" 
                        from="M 4.3730469,26.271484 C 1.950632,26.271484 0,28.222116 0,30.644531 v 5.464844 c 0,2.422415 1.950632,4.373047 4.3730469,4.373047 h 5.4648437 c 2.4224154,0 4.3730474,-1.950632 4.3730474,-4.373047 v -5.464844 c 0,-2.422415 -1.950632,-4.373047 -4.3730474,-4.373047 Z" 
                        to="m 33.027574,5.5018382 c -1.186123,0 -27.7552531,26.7810768 -27.7552531,27.8377258 v 2.388428 c 0,1.058725 0.958836,1.911255 2.144959,1.911255 h 2.6826351 c 1.188714,0 27.756211,-26.781077 27.756211,-27.8377258 V 7.413093 c 0,-1.0587245 -0.957203,-1.9112548 -2.145917,-1.9112548 Z" />
                        <animate className="button-play" attributeName="d" attributeType="XML" dur="0.15s" begin="none" fill="freeze" 
                        from="m 33.027574,5.5018382 c -1.186123,0 -27.7552531,26.7810768 -27.7552531,27.8377258 v 2.388428 c 0,1.058725 0.958836,1.911255 2.144959,1.911255 h 2.6826351 c 1.188714,0 27.756211,-26.781077 27.756211,-27.8377258 V 7.413093 c 0,-1.0587245 -0.957203,-1.9112548 -2.145917,-1.9112548 Z" 
                        to="M 4.3730469,26.271484 C 1.950632,26.271484 0,28.222116 0,30.644531 v 5.464844 c 0,2.422415 1.950632,4.373047 4.3730469,4.373047 h 5.4648437 c 2.4224154,0 4.3730474,-1.950632 4.3730474,-4.373047 v -5.464844 c 0,-2.422415 -1.950632,-4.373047 -4.3730474,-4.373047 Z" />
                    </path> 
                    
                </svg>
            </div>
        </div>
      )
    }
  }
  class Phone extends Component {
    render (){
      return (
  <div className="main">
    <div className="mouse_wheel_block"></div>

    <div className="main_header">
        <div id="logo" className="logo">
            {/* <h1>Open<strong>Tube</strong></h1> */}
            <img src="./images/opentube.svg" />
            <div className="logo_inner">
                <div className="image_z_logo image_z_logo_inner"></div>
                <div className="image_z_logo"></div>
            </div>
        </div>
    </div>
    <div className="title-fig"><FormattedMessage id="app.opentube"/></div>
    <div className="row-item slider-text">
        <div>
            <h1><FormattedMessage id="app.watch_youtube"/></h1>
            <p className="typed-wrap">
                <FormattedMessage id="app.videos_without_ads"/><br/>
                <FormattedMessage id="app.video_to_your_devices_memory"/>
            </p>
            <br/>
            </div>
            <div className="title-box-bottom popap"><h2><strong><FormattedMessage id="app.14_days"/> </strong> <FormattedMessage id="app.free"/></h2><button><FormattedMessage id="app.download_app_now"/></button>
            </div>
            <div>
            <br/>
        </div>
    </div>
    <div className="row-item slider-img">
        <img className="phone_img" src="./images/iPhoneX.png" alt="iPhoneX" />

        <HowItWork />
        <PhoneVideo />

        <div className="image_z" ><div className="before" style={{ backgroundImage: "url(./images/logo_background.svg)"}}></div></div>
        <svg className="svg_hide" version="1.1">
            <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
                <path d="M0 0 0 .0098.0098.0098.0098 0 0 0M.9902 0 .9902.0098 1 .0098 1 0 .9902 0M.334.082C.3115.082.2882.0877.2676.0996C.2264.1229.2031.1657.2031.2129L.2031.7871C.2031.8107.2094.8339.2207.8535C.2319.873.247.8887.2676.9004C.2882.9123.3112.9199.334.9199C.3562.9199.3779.9123.3984.9004C.5682.802.7491.6984.8965.6133C.9171.6014.9341.5858.9453.5664C.9566.5469.9629.5236.9629.5C.9629.4764.9565.4531.9453.4336C.9341.414.9171.3984.8965.3867C.7307.2908.5644.1953.3984.0996C.3779.0877.3565.082.334.082M.334.1855C.3377.1855.3428.1867.3477.1895C.5136.2854.6796.3806.8457.4766C.8576.4836.8574.4951.8574.5C.8569.5043.8557.5163.8438.5234C.6614.6289.524.7086.3477.8105C.3358.8176.324.8126.3203.8105C.3165.8084.3086.8025.3086.7891L.3086.2129C.3086.1993.3165.1921.3203.1895C.3225.1878.328.1855.334.1855M-0 .9902-0 1 .0098 1 .0098.9902-0 .9902M.9902.9902.9902 1 1 1 1 .9902.9902.9902" />
            </clipPath>
        </svg>
    </div>
    <div className="row-item slider-text"></div>

    <div className="main_footer">
        <button className="slider-arrow-next">
            <div><FormattedMessage id="app.home" /></div>
            <div><FormattedMessage id="app.possibility_app" /></div>
            <div><FormattedMessage id="app.demo_app" /></div>
            <div><FormattedMessage id="app.shop" /></div>
            <div><FormattedMessage id="app.news" /></div>
        </button>
    </div>
    <Download />
    <Possibility />
    <Demo />{console.log("this.props.home"),console.log(this.props.home)}
    {/* <Buy /> */}
    <Shop />
    <News />
    <div className="paginator">
        <i></i>
        <div>01</div>
        <div>02</div>
        <div>03</div>
        <div>04</div>
        <div>05</div>
    </div>
  </div>
      )
    }
  }

function SelectLocale(){
    console.log("defaultLocale: "+defaultLocale)
    const [currentLocale, setCurrentLocale] = useState(defaultLocale);
    console.log("currentLocale: "+currentLocale)
    const onChangeLanguage = (e) => {
      const selectedLocale = e.target.lang;
      setCurrentLocale(selectedLocale);
      localStorage.setItem('locale',selectedLocale)
    }
    return (
        <Provider store = {store}>   
            {/* <IntlProvider locale ={locale} messages={lang}>  */}
            <IntlProvider locale ={currentLocale} messages={messages[currentLocale]}>
                <BrowserRouter>
                    <App />
                    <MenuButton currentLocale={currentLocale} onChangeLanguage={onChangeLanguage}/>
                    <Phone />
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
          </Switch> 
                </BrowserRouter>   
            </IntlProvider> 
        </Provider>
    )
}
ReactDOM.render(
  <SelectLocale /> 
    , document.getElementById('root')
);