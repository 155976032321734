import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getHomeFetch } from './redux/actions';



class Shop extends Component {
  componentDidMount = () => {
    this.props.getHomeFetch()
  }

  state = {
  }

  handleBuy = (i) => {
    // console.log(e)
    console.log(i)
  };
  render() {
    if (Array.isArray(this.props.home?.products)) {
      return (
        <div className="buy_popap popap">
          <h2><strong>shop</strong></h2>
          {
            this.props.home?.products.map((product, index) => (
              !product?.hidden ? (
                <button key={index} onClick={this.handleBuy.bind(this, product?.id)}>{product?.name} - {product?.price_local}{product?.currency_local}</button>
              ) : ''
            ))
          }
          {/* <button>1 month - 4.99</button>
            <button>12 month - 29.99</button>
            <button>unlim - 59.99</button> */}
        </div>
      )
    } else {
      return (
        <div className="buy_popap popap">
          <h2><strong>shop</strong></h2>
        </div>
      )
    }
  }
}



const mapStateToProps = state => ({
  home: state.home
})

const mapDispatchToProps = dispatch => ({
  getHomeFetch: () => dispatch(getHomeFetch())
})

export default connect(mapStateToProps, mapDispatchToProps)(Shop);