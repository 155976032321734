import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { BrowserRouter as Switch, Link, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLogoutUser, switchMenu } from './redux/actions';
import Signup from './components/Signup';
import Login from './components/Login';
import Forgot from './components/Forgot';
import Dashboard from './components/Dashboard';
import "./App.css";
let classLogin = "show";
let classSignup = "hide";
let classForgot = "hide";
let marginTop = 0;


class App extends Component {
  handleClick = event => {
    event.preventDefault()
    this.props.getLogoutUser()
  }

  signupClick = () => {
    classLogin = "hide";
    classSignup = "show";
    classForgot = "hide";
    marginTop = 50;
    this.forceUpdate()
  }
  loginClick = () => {
    classLogin = "show";
    classSignup = "hide";
    classForgot = "hide";
    marginTop = -134;
    this.forceUpdate()
  }
  forgotClick = () => {
    classLogin = "hide";
    classSignup = "hide";
    classForgot = "show";
    marginTop = -170;
    this.forceUpdate()
  }

  render() {
    console.log("this.props.currentUser");
    console.log(this.props.currentUser);
    console.log(this.props.currentTranslations);
    return (
      <div className="root_menu">

        {this.props.currentUser?.access_token ? (
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
          </Switch>) : ('')}

        <div className="App">
          {this.props.currentUser?.access_token
            ? (
              <nav className="page_navigations">
                <ul>
                  <li>
                    <Link to="/dashboard" label="Dashboard" ><FormattedMessage id="dashboard" /></Link>
                  </li>
                  <li>
                    <h2>{this.props.currentUser?.email}</h2>
                  </li>
                  <li>
                    <div className="btn-wrap">
                      <button className="btn" onClick={this.handleClick}><FormattedMessage id="app.log_out" />
                        <svg width="8.574358mm" height="9.8692408mm" viewBox="0 0 8.574358 9.8692408" version="1.1">
                          <path d="M 5.5116534,2.38803 2.2173964,0.38070997 c -0.514732,-0.22305 -1.85303797,0.0172 -1.90451297,1.37683003 l -0.01288,6.31798 c -0.0025,1.22139 1.39419097,1.80501 2.17474297,1.31254 l 5.404666,-3.40994 c 0.380397,-0.24 0.627785,-1.58927 0.05564,-2.02354 L 7.5835704,3.6878" />
                        </svg>
                      </button>
                    </div>
                  </li>
                </ul>
              </nav>
            )//<button onClick={this.handleClick}>Log Out</button>
            : (
              <nav>
                <ul className="auth_block" style={{ marginTop: marginTop + "px" }}>
                  <li className={classSignup}>
                    <Signup loginClick={this.loginClick} />
                    <div className="btn_left">
                      <button className="btn" onClick={this.loginClick}><FormattedMessage id="app.login" /></button>
                    </div>
                  </li>
                  <li className={classLogin}>
                    <Login signupClick={this.signupClick} />
                    <div className="btn_left">
                      <button className="btn" onClick={this.forgotClick}><FormattedMessage id="app.forgot_your_password" /></button>
                      <button className="btn" onClick={this.signupClick}><FormattedMessage id="app.create_account" /></button>
                    </div>
                  </li>
                  <li className={classForgot}>
                    <Forgot />
                    <div className="btn_left">
                      <button className="btn" onClick={this.loginClick}><FormattedMessage id="app.login" /></button>
                      <button className="btn" onClick={this.signupClick}><FormattedMessage id="app.create_account" /></button>
                    </div>
                  </li>
                </ul>
              </nav>
            )//null
          }
          <nav className="page_navigations">
            <ul>
              <li style={{ display: "none" }}>
                <FormattedMessage id="app.home">{
                  msg => (<a className="href_" data-text={msg} href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="app.possibility_app">{
                  msg => (<a className="href_" data-text={msg} data-num="02" href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="app.demo_app">{
                  msg => (<a className="href_" data-text={msg} data-num="03" href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="app.shop">{
                  msg => (<a className="href_" data-text={msg} data-num="04" href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="app.news">{
                  msg => (<a className="href_" data-text={msg} data-num="05" href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="app.contacts">{
                  msg => (<a className="href_" data-text={msg} href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

function RouterMenuLink({ label, to, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });
  return (
    <Link className={match ? "active" : ""} to={to}>{label}</Link>
  );
}
function Home() {
  return (
    <div></div>
  );
}
function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  getLogoutUser: () => dispatch(getLogoutUser()),
  switchMenu: () => dispatch(switchMenu())
})

export default connect(mapStateToProps, mapDispatchToProps)(App);